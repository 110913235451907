<template>
  <div class="desktop-height relative mx-4">
    <div class="mt-8 mb-3 text-lg text-center" v-if="processing">
      Please wait...
    </div>
    <div class="mt-8 mb-3 text-lg text-center" v-else>
      {{ user }}
    </div>
  </div>
</template>

<script>

import API from "../../api/http";

import { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";

export default {
  name: "VerifyAccount",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      processing: true
    };
  },
  created() {
    this.verify();
  },
  methods: {
    verify() {
      API.post('email/verify', {
        token: this.$route.params.token
      })
        .then((response) => {
          if (!response.status) {
            this.toast.error(response.message);
            this.$router.push({ name: "Login" });
          } else {
            response.user['token'] = response.token;
            response.user['profile_songs'] = response.profile_songs;
            response.user['artist_songs_count'] = response.artist_songs_count;
            API.setUser(response.user);
            this.$router.push({ name: 'ProfileWithSlug', params: { slug: response.user.slug } });
          }
        });
    },
  },
};
</script>
